import { AudioContext } from 'standardized-audio-context';

class AudioMixer {

  constructor(options = {}) {
    this.options = Object.assign(this.constructor.defaultOptions, options);
    this.context = new AudioContext(this.options);
    this.destination = this.context.createMediaStreamDestination();
    this.constructor.createOscillator(this.context);
    this.masterGainNode = this.context.createGain();
    this.masterGainNode.connect(this.context.destination);
    this.channels = new Map();
    this.context.addEventListener('statechange', () => (this.context.state === 'suspended') && this.context.resume());
  }

  get master() {
    return this.destination.stream;
  }

  set volume(volume) {
      this.masterGainNode.gain.setTargetAtTime(volume, this.context.currentTime, 0.1);
  }

  play(channelId) {
    if (!this.channels.has(channelId)) return;
    const { gainNode } = this.channels.get(channelId);
    gainNode.gain.setTargetAtTime(1, this.context.currentTime, 0.1);
  }

  pause(channelId) {
    if (!this.channels.has(channelId)) return;
    const { gainNode } = this.channels.get(channelId);
    gainNode.gain.setTargetAtTime(0, this.context.currentTime, 0.1);
  }

  stop(channelId) {
    if (!this.channels.has(channelId)) return;
    const { gainNode } = this.channels.get(channelId);
    gainNode.gain.setTargetAtTime(0, this.context.currentTime, 0.1);
  }

  destroy() {
    return this.context.close().catch(e => e);
  }

  channelVolume(channelId, volume) {
    if (!this.channels.has(channelId)) return;
    const { gainNode } = this.channels.get(channelId);
    gainNode.gain.setTargetAtTime(volume, this.context.currentTime, 0.1);
  }

  async addChannel(channelId, ringtoneUrl = '') {
    if (!channelId) throw new Error('The channelId argument is required');
    const response = await fetch(ringtoneUrl);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.context.decodeAudioData(arrayBuffer);
    const source = this.context.createBufferSource();
    source.buffer = audioBuffer;
    source.loop = true;

    const gainNode = this.context.createGain();
    gainNode.gain.setValueAtTime(0, this.context.currentTime);
    source.connect(gainNode);
    gainNode.connect(this.masterGainNode);
    source.start();
    gainNode.gain.setTargetAtTime(0, this.context.currentTime, 0.1);
    this.channels.set(channelId, { source, gainNode });
  }

  removeChannel(channelId) {
    if (!this.channels.has(channelId)) return;

    const { source, gainNode } = this.channels.get(channelId);
    source.stop();
    source.disconnect();
    gainNode.disconnect();
    this.channels.delete(channelId);
  }

  static createOscillator(context) {
    const oscillator = context.createOscillator();
    const gainNode = context.createGain();
    gainNode.gain.setValueAtTime(0, context.currentTime);
    oscillator.connect(gainNode);
    gainNode.connect(context.destination);
    oscillator.start();
    return [oscillator, gainNode];
  }

  static verifyMediaStream(stream) {
    return stream instanceof MediaStream && stream.active;
  }

  static checkAudioTrack(stream) {
    if (!stream) return;
    const [track] = stream.getAudioTracks() || [];
    return track?.readyState === 'live';
  }

  static get defaultOptions() {
    return {
      latencyHint: 'playback',
    }
  }

}

export default AudioMixer;
