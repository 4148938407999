import Vue from 'vue';
import VueI18n from 'vue-i18n';
import call from './i18n/call';
import entities from './i18n/entities';
import profile from './i18n/pages/profile';
import recordings from './i18n/pages/recordings';
import utilities2 from './i18n/pages/utilities';
import meeting from './i18n/meeting';
import menu from './i18n/menu';
import general from './i18n/general';
import times from './i18n/times';
import calendar from './i18n/calendar';
import words from './i18n/words';
import utilities from './i18n/utilities';
import apps from './i18n/apps';

Vue.use(VueI18n);

const messages = {
  en: {
    back: 'Back',
    connecting: 'Connecting...',
    save: 'Save changes',
    clean: 'Clean',
    search: 'Search',
    cancel: 'Cancel',
    select: 'Select',
    selected: 'Selected',
    refresh: 'Refresh',
    comments: 'Comments',
    close: 'Close',
    availability: 'Availability',
    invite: 'Invite',
    logout: 'Session was closed successful.',
    savedAt: 'Saved at',
    timeConnected: 'Connected',
    connect: 'Connect',
    hangup: 'Hangup',
    latency: 'Latency',
    accept: 'Accept',
    selectTime: 'Select time',
    selectToday: 'Select today',
    copyToClipboard: 'Copied to the clipboard',
    date: 'Date',
    state: 'State',
    id: 'Id',
    duration: 'Duration',
    viewNotification: 'View notification',
    markAsRead: 'Mark as read',
    active: 'Active',
    inactive: 'Inactive',
    needHelp: 'Need help?',
    summary: 'Summary',
    timezone: 'Time zone',
    tryAgain: 'Try again',
    notification: 'notification | notifications',
    login: {
      title: 'Login to your account',
      button: 'Sign in',
      sso: 'Enterprise Sign-In (SSO)',
      success: 'Successful sign in',
      placeholder: {
        email: 'Email',
        password: 'Password',
        remember: 'Remember Me',
        terms: 'I accept',
        terms2: 'terms and conditions',
      },
      errors: {
        email: 'Email is required',
        password: 'Password is required',
        match: 'Invalid email and/or password. Please check your credentials.',
        terms: 'You must accept the terms and conditions, in order to log in',
        internet: 'It appears you are not connected to the Internet.',
        subscription: 'Your subscription has expired, contact your administrator',
        ip: 'You are not authorized to connect using the current IP address',
        attempts: 'You have exceeded the attempt limit when logging in',
        server: 'The server is having a bad day, try again',
        session: 'The session has expired, please log in again.',
        '2fa': 'The 2FA code expired, try with a new one',
      },
      suggestionEmail: 'Did you mean:',
      code2fa: '2FA code',
      redirect: 'Remember that you will only be able to access with an agent user, not an administrator. Redirecting to your console...',
    },
    status: {
      connected: 'Your are online and available',
      unavailable: 'Status changed to {status}',
      offline: 'Offline',
      incoming: 'Incoming',
      disconnected: 'Disconnected',
      time: 'Time you are connected today',
      online: 'Available',
      occupied: 'Occupied',
      incall: 'In call',
    },
    menu: Object.assign({
      history: 'History',
      metrics: 'Metrics',
      profile: 'Profile',
      recordings: 'Recordings',
      home: 'Home',
    }, menu.en),
    call: call.en,
    entities: entities.en,
    profile: profile.en,
    times: times.en,
    soon: {
      title: 'Connected',
      subtitle: 'You\'re ready for receive calls.',
    },
    modal: 'What would you like to do?',
    shareScreen: 'Share screen',
    requestShare: 'Request screen sharing',
    clickModal: {
      title: 'You are ready for connect!',
      body: 'Please do click on connect button.',
    },
    devices: {
      desktop: 'Desktop',
      mobile: 'Mobile',
      tablet: 'Tablet',
      undefined: 'Unknown',
    },
    validation: {
      title: 'Hangup conflict',
      mandatoryTags: 'Select at least 1 tag is mandatory',
    },
    calendar: calendar.en,
    words: words.en,
    utilities: Object.assign(utilities.en, utilities2.en),
    notifications: {
      callReconnected: 'You have reconnected from a call in progress. If the client does not connect you can end the call.',
      customerTimeout: 'The client could not connect, the call was ended.',
      customerJoined: 'The client is connecting to the call.',
      customerDisconnected: 'The customer has disconnected from the call.',
      duplicatedSessionTitle: 'Duplicated session',
      duplicatedSessionDescription: 'There is already an active session, please close your other session.',
      answerTimeout: 'You have exceeded the limit to answer the call, it has been automatically rejected.',
      errorAnswering: 'Oops, the call has been answered by another agent',
      hangupError: 'Something has gone wrong, we have received the error report.',
      abandoned: 'The customer has abandoned the call in the queue.',
      newMeeting: 'New meeting on {locale} at {localeTime}',
      newMeetingTitle: 'You have scheduled a meeting',
      statusChangedMeeting: 'Status changed to busy, since you have a meeting that starts at {localeTime}',
      statusChangedMeetingTitle: 'Status changed to busy, meeting mode.',
      expiredSession: 'Your session was expired. Login again.',
      expiredDuringCall: 'Your session expired. Refresh this page, you will be automatically reconnected to the call.',
    },
    recordings: recordings.en,
    meeting: meeting.en,
    general: general.en,
    errors: {
      general: 'Error with code status {code}',
    },
    browser: {
      deprecatedMessage: 'You are using an obsolete version of {browserName}, we cannot ensure correct operation.',
      vulnerableMessage: 'This version of {browserName} {browserVersion} is vulnerable to attacks, update now!',
      currentVersion: 'Current version',
    },
    apps: apps.en,
  },
  es: {
    back: 'Volver',
    connecting: 'Conectando...',
    save: 'Guardar cambios',
    clean: 'Limpiar',
    search: 'Buscar',
    cancel: 'Cancelar',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    refresh: 'Actualizar',
    comments: 'Comentarios',
    close: 'Cerrar',
    availability: 'Disponibilidad',
    invite: 'Invitar',
    logout: 'Sesión cerrada correctamente.',
    savedAt: 'Guardado hace',
    timeConnected: 'Conectado',
    connect: 'Conectar',
    hangup: 'Colgar',
    latency: 'Latencia',
    accept: 'Aceptar',
    selectTime: 'Seleccionar fechas',
    selectToday: 'Seleccionar hoy',
    copyToClipboard: 'Copiado al portapapeles',
    date: 'Fecha',
    state: 'Estado',
    id: 'Id',
    duration: 'Duración',
    viewNotification: 'Ver notificación',
    markAsRead: 'Marcar como leído',
    active: 'Activo',
    inactive: 'Inactivo',
    needHelp: '¿Necesitas ayuda?',
    summary: 'Resumen',
    timezone: 'Zona horaria',
    tryAgain: 'Intentar de nuevo',
    notification: 'notificación | notificaciones',
    login: {
      title: 'Acceda a su cuenta',
      button: 'Iniciar sesión',
      sso: 'Inicio de sesión empresarial (SSO)',
      success: 'Ingreso exitoso.',
      placeholder: {
        email: 'Correo electrónico',
        password: 'Contraseña',
        remember: 'Recordarme',
        terms: 'Acepto los',
        terms2: 'términos y condiciones',
      },
      errors: {
        email: 'Correo electrónico es requerido',
        password: 'Contraseña es requerida',
        match: 'Correo electrónico inválido y/o contraseña. Por favor revise sus credenciales.',
        terms: 'Debe aceptar los términos y condiciones, para poder iniciar sesión',
        internet: 'No estás conectado a Internet',
        subscription: 'Tu suscripción ha caducado, contacta a tu administrador',
        ip: 'No está autorizado a conectarse utilizando la dirección IP actual',
        attempts: 'Has excedido el límite de intentos al iniciar sesión',
        server: 'El servidor está teniendo un mal día, inténtalo de nuevo.',
        session: 'La sesion ha caducado, inicie sesion nuevamente.',
        '2fa': 'El código 2FA ha expirado, intenta con uno nuevo',
      },
      suggestionEmail: '¿Quisiste decir:',
      code2fa: 'Código 2FA',
      redirect: 'Recuerda que sólo podrás acceder con un usuario agente, no con un administrador. Redirigiendo a tu consola...',
    },
    status: {
      connected: 'Estás conectado y disponible',
      unavailable: 'Estado cambiado a {status}',
      offline: 'Sin conexión',
      incoming: 'Entrante',
      disconnected: 'Desconectado',
      time: 'Tiempo que llevas conectado hoy',
      online: 'Disponible',
      occupied: 'Ocupado',
      incall: 'En llamada',
    },
    menu: Object.assign({
      history: 'Historial',
      metrics: 'Métricas',
      profile: 'Perfil',
      recordings: 'Grabaciones',
      home: 'Inicio',
    }, menu.es),
    call: call.es,
    entities: entities.es,
    profile: profile.es,
    times: times.es,
    soon: {
      title: 'Conectado',
      subtitle: 'Estás listo para recibir llamadas.',
    },
    modal: '¿Que desea hacer?',
    shareScreen: 'Compartir pantalla',
    requestShare: 'Solicitar compartir pantalla',
    clickModal: {
      title: '¡Estás listo para conectarte!',
      body: 'Haz click en el botón conectar para comenzar.',
    },
    devices: {
      desktop: 'PC/Laptop',
      mobile: 'Móvil',
      tablet: 'Tablet',
      undefined: 'Desconocido',
    },
    validation: {
      title: 'Conflicto al colgar',
      mandatoryTags: 'Debes selecionar al menos 1 etiqueta',
    },
    calendar: calendar.es,
    words: words.es,
    utilities: Object.assign(utilities.es, utilities2.es),
    notifications: {
      callReconnected: 'Te has vuelto a conectar desde una llamada en curso. Si el cliente no se conecta puedes finalizar la llamada.',
      customerTimeout: 'El cliente no pudo conectarse, se finalizó la llamada.',
      customerJoined: 'El cliente se está conectando a la llamada.',
      customerDisconnected: 'El cliente se ha desconectado de la llamada..',
      duplicatedSessionTitle: 'Sesión duplicada',
      duplicatedSessionDescription: 'Ya hay una sesión activa, cierre la otra sesión.',
      answerTimeout: 'Has superado el límite para contestar la llamada, ésta ha sido rechazada automáticamente.',
      errorAnswering: 'Vaya, la llamada ha sido atendida por otro agente.',
      hangupError: 'Algo salió mal, hemos recibido el informe de error.',
      abandoned: 'El cliente ha abandonado la llamada en la cola.',
      newMeeting: 'Nueva reunión en {locale} a las {localeTime}',
      newMeetingTitle: 'Has programado una reunión',
      statusChangedMeeting: 'El estado cambió a ocupado, ya que tiene una reunión que comienza a las {localeTime}',
      statusChangedMeetingTitle: 'El estado cambió a ocupado, modo de reunión.',
      expiredSession: 'Tu sesión ha expirado. Ingresa nuevamente.',
      expiredDuringCall: 'Tu sesión ha expirado. Refresca esta página, te volverás a conectar automáticamente a la llamada.',
    },
    recordings: recordings.es,
    meeting: meeting.es,
    general: general.es,
    errors: {
      general: 'Error con código de estado {code}',
    },
    browser: {
      deprecatedMessage: 'Estás utilizando una versión de {browserName} obsoleta, no podemos asegurar un correcto funcionamiento.',
      vulnerableMessage: '¡Esta versión de {browserName} {browserVersion} es vulnerable a ataques, actualiza ahora!',
      currentVersion: 'Versión actual',
    },
    apps: apps.es,
  },
  fr: {
    back: 'Retour',
    connecting: 'De liaison...',
    save: 'Enregistrer les modifications',
    clean: 'Nettoyer',
    search: 'Rechercher',
    cancel: 'Annuler',
    select: 'Sélectionner',
    selected: 'Sélectionné',
    refresh: 'Rafraîchir',
    comments: 'Commentaires',
    close: 'Fermer',
    availability: 'Disponibilité',
    invite: 'Inviter',
    logout: 'Déconnexion réussie.',
    savedAt: 'Enregistré à',
    timeConnected: 'Connecté',
    connect: 'Connecter',
    hangup: 'Raccrocher',
    latency: 'Latence',
    accept: 'Accepter',
    selectTime: 'Sélectionner l\'heure',
    selectToday: 'Sélectionner aujourd\'hui',
    copyToClipboard: 'Copié dans le presse-papiers',
    date: 'Date',
    state: 'État',
    id: 'ID',
    duration: 'Durée',
    viewNotification: 'Voir la notification',
    markAsRead: 'Marquer comme lu',
    active: 'Actif',
    inactive: 'Inactif',
    needHelp: 'Besoin d\'aide?',
    summary: 'Résumé',
    timezone: 'Fuseau horaire',
    tryAgain: 'Réessayez',
    notification: 'notification | notifications',
    login: {
      title: 'Connexion à votre compte',
      button: 'Se connecter',
      sso: 'Connexion entreprise (SSO)',
      success: 'Connexion réussie',
      placeholder: {
        email: 'Email',
        password: 'Mot de passe',
        remember: 'Se souvenir de moi',
        terms: 'J\'accepte',
        terms2: 'les termes et conditions',
      },
      errors: {
        email: 'L\'email est requis',
        password: 'Le mot de passe est requis',
        match: 'Email et/ou mot de passe invalides. Veuillez vérifier vos informations d\'identification.',
        terms: 'Vous devez accepter les termes et conditions pour vous connecter',
        internet: 'Il semble que vous ne soyez pas connecté à Internet.',
        subscription: 'Votre abonnement a expiré, contactez votre administrateur',
        ip: 'Vous n\'êtes pas autorisé à vous connecter avec l\'adresse IP actuelle',
        attempts: 'Vous avez dépassé la limite des tentatives de connexion',
        server: 'Le serveur rencontre des problèmes, réessayez',
        session: 'Votre session a expiré, veuillez vous reconnecter.',
        '2fa': 'Le code 2FA a expiré, essayez avec un nouveau code',
      },
      suggestionEmail: 'Vouliez-vous dire :',
      code2fa: 'Code 2FA',
      redirect: 'Rappelez-vous que vous ne pourrez accéder qu\'avec un utilisateur agent, pas un administrateur. Redirection vers votre console...',
    },
    status: {
      connected: 'Vous êtes en ligne et disponible',
      unavailable: 'Statut changé en {status}',
      offline: 'Hors ligne',
      incoming: 'Entrant',
      disconnected: 'Déconnecté',
      time: 'Temps de connexion aujourd\'hui',
      online: 'Disponible',
      occupied: 'Occupé',
      incall: 'En appel',
    },
    menu: Object.assign({
      history: 'Historique',
      metrics: 'Métriques',
      profile: 'Profil',
      recordings: 'Enregistrements',
      home: 'Accueil',
    }, menu.fr),
    call: call.fr,
    entities: entities.fr,
    profile: profile.fr,
    times: times.fr,
    soon: {
      title: 'Connecté',
      subtitle: 'Vous êtes prêt à recevoir des appels.',
    },
    modal: 'Que souhaitez-vous faire?',
    shareScreen: 'Partager l\'écran',
    requestShare: 'Demander le partage d\'écran',
    clickModal: {
      title: 'Vous êtes prêt à vous connecter!',
      body: 'Veuillez cliquer sur le bouton de connexion.',
    },
    devices: {
      desktop: 'Bureau',
      mobile: 'Mobile',
      tablet: 'Tablette',
      undefined: 'Inconnu',
    },
    validation: {
      title: 'Conflit de raccrochage',
      mandatoryTags: 'Sélectionner au moins 1 tag est obligatoire',
    },
    calendar: calendar.fr,
    words: words.fr,
    utilities: Object.assign(utilities.fr, utilities2.fr),
    notifications: {
      callReconnected: 'Vous avez été reconnecté à un appel en cours. Si le client ne se connecte pas, vous pouvez terminer l\'appel.',
      customerTimeout: 'Le client n\'a pas pu se connecter, l\'appel a été terminé.',
      customerJoined: 'Le client se connecte à l\'appel.',
      customerDisconnected: 'Le client s\'est déconnecté de l\'appel.',
      duplicatedSessionTitle: 'Session dupliquée',
      duplicatedSessionDescription: 'Il y a déjà une session active, veuillez fermer votre autre session.',
      answerTimeout: 'Vous avez dépassé le délai pour répondre à l\'appel, il a été automatiquement rejeté.',
      errorAnswering: 'Oups, l\'appel a été répondu par un autre agent',
      hangupError: 'Une erreur est survenue, nous avons reçu le rapport d\'erreur.',
      abandoned: 'Le client a abandonné l\'appel en attente.',
      newMeeting: 'Nouvelle réunion le {locale} à {localeTime}',
      newMeetingTitle: 'Vous avez planifié une réunion',
      statusChangedMeeting: 'Le statut a changé en occupé, car vous avez une réunion qui commence à {localeTime}',
      statusChangedMeetingTitle: 'Statut changé en occupé, mode réunion.',
      expiredSession: 'Votre session a expiré. Connectez-vous à nouveau.',
      expiredDuringCall: 'Votre session a expiré. Actualisez cette page, vous vous reconnecterez automatiquement à l\'appel',
    },
    recordings: recordings.fr,
    meeting: meeting.fr,
    general: general.fr,
    errors: {
      general: 'Erreur avec le code statut {code}',
    },
    browser: {
      deprecatedMessage: 'Vous utilisez une version obsolète de {browserName}, nous ne pouvons pas garantir un fonctionnement correct.',
      vulnerableMessage: 'Cette version de {browserName} {browserVersion} est vulnérable aux attaques, mettez à jour maintenant!',
      currentVersion: 'Version actuelle',
    },
    apps: apps.fr,
  }
};

const i18n = new VueI18n({
  locale: (window.navigator.language) && window.navigator.language.split('-')[0], // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
