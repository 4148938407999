import WebAuth from '@videsk/jwt-webauth';
import axios from './axios';

function wait(timeout = 1000) {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

const options = { delay: 3 };

const auth = new WebAuth(options);
window.auth = auth;

async function requestInterceptor(config) {
  const whitelist = ['/auth/logout', '/auth/login', '/auth', '/auth/password-reset'];
  if (config && 'headers' in config && !whitelist.includes(config.url)) config.headers.Authorization = `Bearer ${auth.getTokens('accessToken')}`;
  return config;
}

function errorInterceptor(error) {
  return Promise.reject(error);
}

axios.interceptors.request.use(requestInterceptor, errorInterceptor);

async function retryInterceptorSetup(error) {
  try {
    await auth.renew();
    const accessToken = auth.getTokens('accessToken');
    error.config.headers.Authorization = `Bearer ${accessToken}`;
    return axios.request(error.config);
  } catch (catchError) {
    return Promise.reject(catchError);
  }
}

axios.interceptors.response.use(null, async (error) => {
  if (error.config && error.response && error.response.status === 401 && !(error.config.url.includes('auth/'))) return retryInterceptorSetup(error);
  if (error.config && error.response && error.response.status === 429) {
    await wait(1000);
    return retryInterceptorSetup(error);
  }
  return Promise.reject(error);
});

auth.on('verify', accessToken => axios.get('/auth/check-token', { headers: { Authorization: `Bearer ${accessToken}` } }).catch(error => {
  document.dispatchEvent(new CustomEvent('http-error', { detail: { message: error.message, code: error.response?.status } }));
  return error;
}));

auth.on('renew', async (refreshToken) => {
  const response = await axios.post('/auth/refresh-token', { refreshToken }).catch(error => {
    document.dispatchEvent(new CustomEvent('http-error', { detail: { message: error.message, code: error.response?.status } }));
    if (error.response?.status === 401) auth.logout();
    return error;
  });
  return response.data.accessToken;
});

auth.on('renewed', () => {
  axios.defaults.headers.common.Authorization = `Bearer ${auth.getTokens('accessToken')}`;
});

export default auth;
