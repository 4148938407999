class SocketWorker extends EventTarget {
  constructor(workerPath = "") {
    super();
    this.worker = new Worker(workerPath);
    this.worker.addEventListener("message", (event) =>
      this.dispatchEvent(...event.data)
    );
    this.events = {};
    this.listenAny = () => {};
    this.readyState = WebSocket.CLOSED;
  }

  connect(options = {}) {
    return new Promise((resolve, reject) => {
      this.addEventListener('readyState', event => {
        const [readyState] = event.detail;
        if (readyState === WebSocket.OPEN) resolve();
        else if (readyState > 1) reject();
      }, { once: true });
      this.worker.postMessage({ event: "connect", data: options });
    });
  }

  emit(event, data) {
    if (this.readyState === WebSocket.OPEN) this.worker.postMessage({ event: "emit", data: { event, data } });
  }

  any(callback) {
    this.listenAny = callback;
  }

  on(eventName = "", callback = () => {}) {
    this.events[eventName] = callback;
  }

  disconnect() {
    this.worker.postMessage({ event: 'disconnect' });
  }

  dispatchEvent(eventName, args) {
    if (eventName === 'readyState') this.readyState = args[0];
    if (eventName in this.events) this.events[eventName](...args);
    this.listenAny(eventName, ...args);
    const customEvent = new CustomEvent(eventName, { detail: args });
    super.dispatchEvent(customEvent);
  }
}

export default SocketWorker;
