<template>
  <div class="main-menu-container">
    <div
      class="statuses-container"
    >
      <a-dropdown
        placement="topLeft"
        class="statuses-dropdown"
        :style="{ 'background-color': statusColor, 'border-color': statusColor }"
      >
        <a-menu slot="overlay">
          <a-menu-item
            v-for="statusOption in statuses"
            :key="statusOption.id"
            :disabled="status === statusOption.id"
            :class="{ selected: status === statusOption.id }"
            @click="changeStatus(statusOption.id)"
          >
              <div class="status-item-container">
                <a-badge :color="statusOption.color" :text="statusOption.id in builtInStatuses ? builtInStatuses[statusOption.id] : statusOption.label" /> <small class="hangup-status" v-show="isActive">{{ $t('hangup') }} <a-icon type="phone"></a-icon></small>
              </div>
          </a-menu-item>
        </a-menu>
        <a-button> {{ getStatusLabel() }} <a-icon :type="statusIcon" /> </a-button>
      </a-dropdown>
    </div>
    <div class="icons-menu">
      <a-tooltip
      >
        <template slot="title">{{ $t('menu.home') }}</template>
        <div
          class="item-menu"
          @click="changePage('home')"
        >
          <a-icon
            class="icon"
            type="home"
          />
        </div>
      </a-tooltip>
      <a-tooltip
      >
        <template slot="title">{{ $t('menu.utilities') }}</template>
        <div
          class="item-menu"
          @click="changePage('utilities')"
        >
          <a-icon
            class="icon"
            type="link"
          />
        </div>
      </a-tooltip>
      <a-tooltip
      >
        <template slot="title">{{ $t('menu.recordings') }}</template>
        <div
          class="item-menu"
          @click="changePage('recordings')"
        >
          <a-icon
            class="icon"
            type="play-square"
          />
        </div>
      </a-tooltip>
      <a-tooltip
      >
        <template slot="title">{{ $t('menu.profile') }}</template>
        <div
          class="item-menu"
          @click="changePage('profile')"
        >
          <a-badge
            v-if="unreadNotifications > 0"
            :count="unreadNotifications"
            class="badge-notifications"
          />
          <a-icon
            class="icon"
            type="user"
          />
        </div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    status: 'disconnected',
  }),
  computed: {
    statusColor() {
      if (this.status === 'disconnected') return 'red';
      const status = this.statuses.find(x => x.id === this.status || x.name === this.status);
      return status ? status.color : 'rgb(234, 179, 8)';
    },
    unreadNotifications() {
      return this.$store.getters['global/notifications'].length;
    },
    disabled() {
      return this.$store.getters['call/active'] || this.$store.getters['call/inbound'] || this.$store.getters['global/disconnected'] || this.$store.getters['calendar/inMeeting'];
    },
    statuses() {
      const { statuses = [] } = this.$store.getters['global/settings'] || {};
      return statuses;
    },
    statusIcon() {
      const icons = { available: 'check-circle', disconnected: 'minus-circle' };
      return this.status in icons ? icons[this.status] : 'pause-circle';
    },
    isActive() {
        return this.$store.getters['call/active'];
    },
    inMeeting() {
        return this.$store.getters['calendar/inMeeting'];
    },
    builtInStatuses() {
      return this.$t('menu.statuses');
    }
  },
  mounted() {
    document.addEventListener('status-changed', event => {
      const { available, statusValue } = event.detail;
      this.status = statusValue;
      const label = this.getStatusLabel();
      if (available) this.$notification.success({ message: '', description: this.$t('status.connected'), key: 'connected-status' });
      else this.$notification.info({ message: this.$t('status.unavailable', { status: `"${label.toLowerCase()}"` }), key: 'unavailable-status' });
    });
    document.addEventListener('disconnected', () => {
      this.status = 'disconnected';
    })
  },
  methods: {
    changePage(view) {
      if (this.disabled) return;
      this.$mixpanel.track(view);
      this.$router.push({ name: view }).catch(() => {});
    },
    getStatusLabel() {
      const value = this.statuses.find(status => status.id === this.status || status.name === this.status);
      return (value?.id in this.builtInStatuses || this.status in this.builtInStatuses ? this.$t(`menu.statuses.${this.status}`) : value?.label) || this.$t('menu.statuses.disconnected');
    },
    changeStatus(statusId) {
      if (this.isActive) {
          this.$store.commit('global/statusHangup', statusId);
          return document.querySelector('videsk-webrtc')?.hangup();
      }
      const socket = this.$store.getters['global/socket'];
      socket.emit('change:status', { status: statusId });
    },
  },
};
</script>

<style>
.main-menu-container {
  width: 100%;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  border-top: 1px solid #d6d8dc;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.item-menu {
  width: 30px;
  height: 100%;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.item-menu i {
  font-size: 1.6em;
}

.main-menu-container > div,
.main-menu-container > div > span,
.main-menu-container > div > span > div,
.main-menu-container > div > span > div > i {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-notifications {
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
}

.badge-notifications * {
  color: white !important;
  font-size: 0.8rem;
}

.ant-dropdown-menu-item.selected {
  background: #efefef;
}

.statuses-container {
  width: 180px;
}

.statuses-dropdown {
  width: 100%;
  color: white !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.statuses-container[disabled] {
  pointer-events: none;
}

.status-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 60%;
}

.status-item-container small {
    opacity: 0;
}

.status-item-container:hover small {
    opacity: 1;
}
</style>
