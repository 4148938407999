/* eslint-disable linebreak-style */
import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/plugins/vue-router/routes';


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  })
}

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  routes,
});

export default router;
