import login from '../../views/login/index.vue';
import home from '../../views/main/index.vue';

// Views
const recordings = () => import('../../views/recordings/index.vue');
const home2 = () => import('../../views/home/index.vue');
const profile = () => import('../../views/profile/index.vue');
const twoFactor = () => import('../../views/profile/2fa/index.vue');
const virtualBackground = () => import('../../views/profile/virtual-background/index.vue');
const helpCenter = () => import ('../../views/profile/helpcenter/index.vue');
const notifications = () => import('../../views/profile/notifications/index.vue');
const utilities = () => import('../../views/utilities/index.vue');



const routes = [
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  { path: '/login/claim/:id', name: 'claim', component: login, props: true },
  {
    path: '/',
    name: 'home',
    component: home,
    redirect: '/history',
    children: [
      { path: '/recordings', name: 'recordings', component: recordings },
      { path: '/home', name: 'home', component: home2 },
      { path: '/utilities', name: 'utilities', component: utilities },
      { path: '/profile', name: 'profile', component: profile },
      { path: '/profile/help-center', name: 'help-center', component: helpCenter },
      { path: '/profile/2fa', name: '2fa', component: twoFactor },
      { path: '/profile/virtual-background', name: 'virtual-background', component: virtualBackground },
      { path: '/profile/notifications', name: 'notifications', component: notifications },
    ],
  },
  {
    path: '*',
    beforeEnter() {
      window.location = '/login';
    },
  }
];

export default routes;
